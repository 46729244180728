<template>
  <loader
    v-if="isLoading"
    message="Loading ...."
  />
  <div v-else>
    <v-container class="pa-0 pt-3">
      <app-logs
        title="Error Logs"
        :logs="logs"
      />
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import AppLogs from '@/components/apps/AppLogs'
import Loader from '@/components/cards/Loader'
import { getHumanReadableDate } from '@/utils/date'
export default {
  name: 'AppLogs',
  components: {
    loader: Loader,
    'app-logs': AppLogs,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      logs: 'user/currentUserErrorLogs',
    }),
  },
  data () {
    return {
      isLoading: false,
      showLogDetailsModal: false,
      log: null,
    }
  },
  methods: {
    getHumanReadableDate,
    getLogs () {
      this.isLoading = true
      this.$store
        .dispatch('user/getUserErrorLogs', this.id)
        .finally(() => {
          this.isLoading = false
        })
    },
    openLogDetails (log) {
      this.showLogDetailsModal = true
      this.log = log
    },
  },
  watch: {
    logs: {
      handler () {
        if (this.logs) return
        this.getLogs()
      },
      immediate: true,
    },
  },
}
</script>
