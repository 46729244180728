<template>
  <v-card
    outlined
    color="#000000"
    dark
    class="build-log"
  >
    <v-card-title class="pt-2 pb-0">
      <v-icon left small>build</v-icon>
      <span class="overline">{{  title }}</span>
    </v-card-title>
    <v-divider />
    <div class="build-log-container">
      <v-card-text>
        <v-list-item-title
          :key="index"
          v-for="(log, index) in logs"
          class="text-wrap"
        >
          <span class="line-number">{{index}}</span>
          <span class="text--white logs">{{ log }}</span>
        </v-list-item-title>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'AppLogs',
  props: {
    title: {
      type: String,
      default: null,
    },
    logs: {
      type: Array,
      default: null,
    },
  },
}
</script>
<style lang="scss" scoped>
.build-log-container {
  overflow-y: scroll;
  font-family: courier;
  padding-left: 24px;

  * {
    font-size: 14px;
  }

  .line-number {
    width: 26px;
    display: inline-block;
    color: #555;
  }

  .logs {
    word-wrap: break-word;
    word-break: normal;
  }
}
</style>
